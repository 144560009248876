/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { ReactElement } from 'react';
import ActionButton from '../../../components/ActionButton/ActionButton';
import Panel from '../../../components/Panel/Panel';
import { mapToFormModel } from '../../../mappers/UserEditMapper';
import { ButtonSize } from '../../../types/ButtonSize';
import { DataDomainPermission } from '../../../types/DataDomainPermission';
import { DataDomainPermissionMetadata } from '../../../types/DataDomainPermissionMetadata';
import { PanelType } from '../../../types/PanelType';
import { DataDomainPermissionsPropTypes } from '../../../types/propTypes/DataDomainPermissionsPropTypes';
import { UserEditFormModel } from '../../../types/UserEditModel';
import { UserEditViewModel } from '../../../types/UserEditViewModel';
import { isStateUser } from '../../../utilities/userUtilities';
import DataDomainPermissionSelection from '../DataDomainPermissionSelection/DataDomainPermissionSelection';

const DataDomainPermissions = ({
  model,
  schoolOptions,
  clearParentError,
}: DataDomainPermissionsPropTypes): ReactElement => {
  const { setValues, values } = useFormikContext();

  const selectAllSchoolAssignments = (): UserEditViewModel => {
    const existing = values as UserEditFormModel;
    const allSchools = model.assignableSchools;
    const updatedPermissionsByDataDomain: Record<string, DataDomainPermission> =
      {};
    model.assignableDataDomainPermissions.forEach(
      (dataDomainPermissionMetadata: DataDomainPermissionMetadata) => {
        updatedPermissionsByDataDomain[dataDomainPermissionMetadata.name] = {
          schools: allSchools,
          canCertifyDistrict:
            existing.permissionsByDataDomain[dataDomainPermissionMetadata.name]
              .canCertifyDistrict,
          canCertifySchools:
            existing.permissionsByDataDomain[dataDomainPermissionMetadata.name]
              .canCertifySchools,
          msisIdRequestAccess:
            existing.permissionsByDataDomain[dataDomainPermissionMetadata.name]
              .msisIdRequestAccess,
          studentOwnershipAccess:
            existing.permissionsByDataDomain[dataDomainPermissionMetadata.name]
              .studentOwnershipAccess,
        };
      }
    );

    return {
      ...model,
      user: {
        ...model.user,
        permissionsByDataDomainForEditingDistrict:
          updatedPermissionsByDataDomain,
      },
    };
  };

  const handleSelectAll = (setFormValues: Function): void => {
    clearParentError();
    const tempDetails = selectAllSchoolAssignments();
    setFormValues(mapToFormModel(tempDetails));
  };

  const selectNoneSchoolAssignments = (): UserEditViewModel => {
    const updatedPermissionsByDataDomain: Record<string, DataDomainPermission> =
      {};
    model.assignableDataDomainPermissions.forEach(
      (dataDomainPermissionMetadata: DataDomainPermissionMetadata) => {
        updatedPermissionsByDataDomain[dataDomainPermissionMetadata.name] = {
          schools: [],
          canCertifyDistrict: false,
          canCertifySchools: false,
          msisIdRequestAccess: false,
          studentOwnershipAccess: false,
        };
      }
    );

    return {
      ...model,
      user: {
        ...model.user,
        permissionsByDataDomainForEditingDistrict:
          updatedPermissionsByDataDomain,
      },
    };
  };

  const handleSelectNone = (setFormValues: Function): void => {
    clearParentError();
    const tempDetails = selectNoneSchoolAssignments();
    setFormValues(mapToFormModel(tempDetails));
  };

  const disableSelectAll = (inputValues: UserEditFormModel): boolean => {
    const allSchoolsSelected = (): boolean => {
      const allSchoolIds = model.assignableSchools.map((scl) => scl.schoolId);
      const selectedSchoolIdSets = Object.values(
        inputValues.permissionsByDataDomain
      );
      return selectedSchoolIdSets.every((idSet) =>
        isEqual(idSet.schoolIds, allSchoolIds)
      );
    };

    return allSchoolsSelected();
  };

  const disableUnselectAll = (inputValues: UserEditFormModel): boolean => {
    const noSchoolsAssigned = (): boolean =>
      Object.values(inputValues.permissionsByDataDomain).every(
        (record) => record.schoolIds.length === 0
      );

    return noSchoolsAssigned();
  };

  return !isStateUser(model.user.userType) ? (
    <div className="multi-selection-section" data-testid="multi-select-section">
      <Panel panelType={PanelType.INFO} heading={'Data Domain Permissions'}>
        <div className="button-group">
          <ActionButton
            buttonType="button"
            classes="button no-wrap-text select-all-button"
            onClick={() => handleSelectAll(setValues)}
            dataTestId="edit-select-all"
            disabled={disableSelectAll(values as UserEditFormModel)}
            cypressDataId="edit-select-all"
            tooltipText="Select All"
            size={ButtonSize.SMALL}
          >
            <span>Select All</span>
          </ActionButton>
          <ActionButton
            buttonType="button"
            classes="button no-wrap-text select-none-button"
            onClick={() => handleSelectNone(setValues)}
            dataTestId="edit-select-none"
            disabled={disableUnselectAll(values as UserEditFormModel)}
            cypressDataId="edit-select-none"
            tooltipText="Select None"
            size={ButtonSize.SMALL}
          >
            <span>Select None</span>
          </ActionButton>
        </div>
        {model.assignableDataDomainPermissions &&
          model.assignableDataDomainPermissions.map(
            (dataDomainPermissionMetadata) => (
              <DataDomainPermissionSelection
                key={`permission-${dataDomainPermissionMetadata.name}-selection`}
                dataDomainPermissionMetadata={dataDomainPermissionMetadata}
                schoolOptions={schoolOptions}
              />
            )
          )}
      </Panel>
    </div>
  ) : (
    <></>
  );
};

export default DataDomainPermissions;
