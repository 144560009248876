import { ReactElement } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { selectLEAManagementDataEntryMode } from 
  '../../../redux/slices/leaManagementSlice';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { PanelType } from '../../../types/PanelType';
import { LEAManagementValidationEditSchema } from 
  '../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from 
  '../../../types/Validation/LEAManagementValidationSchema';
import {
  isDistrictAdmin,
  isStateAdmin,
} from '../../../utilities/userUtilities';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';

const LEAManagementPrimaryInformationPanel = (): ReactElement => {
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectLEAManagementDataEntryMode
  );
  return (
    <Panel panelType={PanelType.INFO} heading="MSIS Primary Information">
      <div className="field-row">
        <FormTextbox
          displayName="First Name"
          field="primaryFirstName"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
        <FormTextbox
          displayName="Last Name"
          field="primaryLastName"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
      </div>

      <div className="field-row">
        <FormTextbox
          displayName="Email Address"
          field="primaryEmailAddress"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
        <FormTextbox
          displayName="Telephone Number"
          field="primaryTelephoneNumber"
          validationSchema={
            dataEntryMode === 'new'
              ? LEAManagementValidationSchema
              : LEAManagementValidationEditSchema
          }
          disabled={!isStateAdmin() && !isDistrictAdmin()}
        />
      </div>
    </Panel>
  );
};

export default LEAManagementPrimaryInformationPanel;
