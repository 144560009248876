import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionButton from '../../components/ActionButton/ActionButton';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import NavigationButton from '../../components/NavigationButton/NavigationButton';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import Pill from '../../components/Pill/Pill';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectUserDetailsFormModel,
  selectUserLoading,
} from '../../redux/slices/userSlice';
import { fetchUserDetailsViewModelById } from '../../redux/thunks/userThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { canUserAccessRoute } from '../../services/security.service';
import { activateUser, deactivateUser } from '../../services/user.service';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { PanelType } from '../../types/PanelType';
import { PillType } from '../../types/PillType';
import { UserDetailsFormModel } from '../../types/UserDetailsViewModel';
import { UserStatus } from '../../types/UserStatus';
import { UserType } from '../../types/UserType';
import {
  getRoleDisplayText,
  isActive,
  isCurrentUser,
} from '../../utilities/userUtilities';
import './UserDetail.css';

const UserDetail = (): ReactElement => {
  const { userId } = useParams();

  const dispatch = useAppDispatch();
  const confirmContext = useConfirmationModalContext();
  const isLoading = useAppSelector(selectUserLoading);
  const userDetails: UserDetailsFormModel = useAppSelector(
    selectUserDetailsFormModel
  );
  const [apiMessage, setAPIMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    let promise: unknown = undefined;
    if (userId) {
      promise = dispatch(fetchUserDetailsViewModelById(userId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, userId]);

  const userIsActive =
    userId !== undefined && userDetails.userStatus === UserStatus.Active;

  const handleActivate = async (id: string): Promise<void> => {
    confirmContext.setOptions({
      title: 'Activate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to activate this user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, activate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await activateUser(id);
        if (isValidCode(response.status)) {
          setAPIMessage('User activated successfully.');
          dispatch(fetchUserDetailsViewModelById(id));
        } else {
          setAPIMessage(undefined);
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleDeactivate = async (id: string): Promise<void> => {
    confirmContext.setOptions({
      title: 'Deactivate user for district?',
      content: (
        <div className="confirm-container">
          Are you sure you want to deactivate the user for the district &quot;
          {getCurrentUserAccess()?.currentDistrict?.name}&quot;?
        </div>
      ),
      confirmText: 'Yes, deactivate user',
      cancelText: 'No, cancel',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deactivateUser(id);
        if (isValidCode(response.status)) {
          setAPIMessage('User deactivated successfully.');
          dispatch(fetchUserDetailsViewModelById(id));
        } else {
          setAPIMessage(undefined);
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  const buttonRender = (id?: string): ReactElement =>
    userIsActive ? (
      <NavigationButton
        dataTestId="edit-button"
        route={appRoutePaths.UserEdit(id)}
        classes="button no-wrap-text"
        visibleOverride={canUserAccessRoute(appRoutePaths.UserEdit())}
        tooltipText="Edit User"
      >
        <>Edit User</>
      </NavigationButton>
    ) : (
      <ActionButton
        dataTestId="activate-button"
        onClick={() => userId && handleActivate(userId)}
        tooltipText="Activate User"
      >
        <span>Activate User</span>
      </ActionButton>
    );

  const headerNameWithPill = isLoading ? (
    ''
  ) : (
    <>
      {userDetails.name}
      <div className="page-pill-heading">
        <Pill
          value={userDetails.userStatus}
          pillType={PillType.USER_STATUS}
          status={userDetails.userStatus}
        />
      </div>
    </>
  );

  return (
    <NavigationPage
      heading={headerNameWithPill}
      pageClass="user-management--detail"
      isLoading={isLoading}
      loadingDataId="user-detail-loader"
      loadingText="Getting user details"
      backBarLocation={appRoutePaths.UserList}
      contentClass="user-detail-layout"
      headerChild={buttonRender(userId)}
    >
      {apiMessage && (
        <InfoPanel message={apiMessage} containerClass="user-detail-success" />
      )}

      <Panel panelType={PanelType.INFO} heading="General Information">
        <LabelAndValue
          cypressDataId="user-email"
          displayName="Email"
          value={userDetails.email}
        />
        <LabelAndValue
          cypressDataId="user-district-access"
          displayName="District"
          value={userDetails.currentDistrict?.name}
        />
        {!isCurrentUser(userId || '') && isActive(userDetails.userStatus) && (
          <div>
            <ActionButton
              dataTestId="deactivate-button"
              classes="button--small"
              onClick={() => userId && handleDeactivate(userId)}
              tooltipText="Deactivate User"
            >
              Deactivate User
            </ActionButton>
          </div>
        )}
      </Panel>
      <div className="list-display">
        <h2>Global Permissions</h2>
        {userDetails.statewideDataDomainPermissions.length > 0 && (
          <table cellSpacing={0} className="page-view-table">
            <thead>
              <tr>
                <th>Permission</th>
              </tr>
            </thead>
            <tbody>
              {userDetails.statewideDataDomainPermissions.map((role) => (
                <tr key={`${role}`} data-testid={role}>
                  <td>{getRoleDisplayText(role)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {userDetails.statewideDataDomainPermissions.length === 0 && (
          <div className="empty--list-text">
            <p>No global permissions assigned</p>
          </div>
        )}
      </div>
      {userDetails.userType === UserType.District && (
        <div className="list-display">
          <h2>School Permissions</h2>
          {userDetails.dataDomainPermissionsForCurrentDistrict.length > 0 && (
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>Permission</th>
                  <th>School(s)</th>
                  <th>Additional Permissions</th>
                </tr>
              </thead>
              <tbody>
                {userDetails.dataDomainPermissionsForCurrentDistrict.map(
                  (assignment) => (
                    <tr
                      key={`${assignment.role}`}
                      data-testid={`row-${assignment.role}`}
                    >
                      <td>{getRoleDisplayText(assignment.role)}</td>
                      <td>{assignment.schools}</td>
                      <td data-testid="additional-permissions">
                        {assignment.additionalPermissions}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
          {userDetails.dataDomainPermissionsForCurrentDistrict.length === 0 && (
            <div className="empty--list-text">
              <p>No school permissions assigned</p>
            </div>
          )}
        </div>
      )}
    </NavigationPage>
  );
};

export default UserDetail;
