/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { useAppSelector } from '../../../../../redux/hooks';
import { selectNonPublicStudentScheduleFormViewModel } from '../../../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentScheduleFormViewModel } from '../../../../../types/NonPublicStudentScheduleFormViewModel';
import { NonPublicStudentScheduleModel } from '../../../../../types/NonPublicStudentScheduleModel';
import { PanelType } from '../../../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../../../types/propTypes/FormDropdownPropTypes';
import { SelectOption } from '../../../../../types/SelectOption';
import { NonPublicStudentEntryScheduleValidationSchema } from '../../../../../types/Validation/NonPublicStudentEntryScheduleValidationSchema';
import FormDropdown from '../../../../FormInputs/FormDropdown/FormDropdown';
import Panel from '../../../../Panel/Panel';
import './NPSE_AddCoursePanel.css';

const NPSE_AddCoursePanel = (): ReactElement => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<NonPublicStudentScheduleModel>();

  const formViewModel: NonPublicStudentScheduleFormViewModel = useAppSelector(
    selectNonPublicStudentScheduleFormViewModel
  );

  const clearCourseCodeDependentFields = (): void => {
    setFieldValue('schoolIdentifier', '');
    setFieldTouched('schoolIdentifier', false);

    setFieldValue('staffMemberIdentifier', '');
    setFieldTouched('staffMemberIdentifier', false);

    setFieldValue('courseSectionIdentifier', '');
    setFieldTouched('courseSectionIdentifier', false);
  };

  const clearSchoolDependentFields = (): void => {
    setFieldValue('staffMemberIdentifier', '');
    setFieldTouched('staffMemberIdentifier', false);

    setFieldValue('courseSectionIdentifier', '');
    setFieldTouched('courseSectionIdentifier', false);
  };

  const clearTeacherDependentFields = (): void => {
    setFieldValue('courseSectionIdentifier', '');
    setFieldTouched('courseSectionIdentifier', false);
  };

  const handleCourseCodeChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    clearCourseCodeDependentFields();

    setFieldValue('courseTitle', props.optionText);

    return Promise.resolve();
  };

  const handleSchoolIdentifierChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    clearSchoolDependentFields();

    setFieldValue('schoolName', props.optionText);

    return Promise.resolve();
  };

  const handleTeacherChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    clearTeacherDependentFields();

    setFieldValue('staffMemberName', props.optionText);

    return Promise.resolve();
  };

  const handleCourseSectionChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    setFieldValue('courseSectionDescription', props.optionText);

    return Promise.resolve();
  };

  const getSchoolIdentifierValues = (): SelectOption[] => {
    if (
      formViewModel.extendedCourseCodeOptions.length === 0 ||
      values.courseIdentifier === ''
    ) {
      return [];
    }

    return formViewModel.extendedCourseCodeOptions.filter(
      (x) => x.value === values.courseIdentifier
    )[0].extendedSchoolOptions;
  };

  const getTeacherValues = (): SelectOption[] => {
    if (
      formViewModel.extendedCourseCodeOptions.length === 0 ||
      values.courseIdentifier === '' ||
      values.schoolIdentifier === ''
    ) {
      return [];
    }

    return formViewModel.extendedCourseCodeOptions
      .filter((x) => x.value === values.courseIdentifier)[0]
      .extendedSchoolOptions.filter(
        (s) => s.value === values.schoolIdentifier
      )[0].extendedTeacherOptions;
  };

  const getCourseSectionValues = (): SelectOption[] => {
    if (
      formViewModel.extendedCourseCodeOptions.length === 0 ||
      values.courseIdentifier === '' ||
      values.schoolIdentifier === '' ||
      values.staffMemberIdentifier === ''
    ) {
      return [];
    }

    return formViewModel.extendedCourseCodeOptions
      .filter((x) => x.value === values.courseIdentifier)[0]
      .extendedSchoolOptions.filter(
        (s) => s.value === values.schoolIdentifier
      )[0]
      .extendedTeacherOptions.filter(
        (t) => t.value === values.staffMemberIdentifier
      )[0].courseSectionOptions;
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Add a Course">
      <div className="field-row field-row-grid-1-1-1">
        <FormDropdown
          displayName="Course Code"
          field="courseIdentifier"
          validationSchema={NonPublicStudentEntryScheduleValidationSchema}
          onChangeEvent={handleCourseCodeChange}
          options={formViewModel.extendedCourseCodeOptions}
        />
        <FormDropdown
          displayName="School Identifier"
          field="schoolIdentifier"
          validationSchema={NonPublicStudentEntryScheduleValidationSchema}
          disabled={values.courseIdentifier === ''}
          onChangeEvent={handleSchoolIdentifierChange}
          options={getSchoolIdentifierValues()}
        />
      </div>
      <div className="field-row field-row-grid-1-1-1">
        <FormDropdown
          displayName="Teacher"
          field="staffMemberIdentifier"
          validationSchema={NonPublicStudentEntryScheduleValidationSchema}
          disabled={values.schoolIdentifier === ''}
          onChangeEvent={handleTeacherChange}
          options={getTeacherValues()}
        />
        <FormDropdown
          displayName="Course Section"
          field="courseSectionIdentifier"
          validationSchema={NonPublicStudentEntryScheduleValidationSchema}
          disabled={values.staffMemberIdentifier === ''}
          onChangeEvent={handleCourseSectionChange}
          options={getCourseSectionValues()}
        />
        <FormDropdown
          displayName="Status"
          field="status"
          validationSchema={NonPublicStudentEntryScheduleValidationSchema}
          options={formViewModel.statusOptions}
        />
      </div>
    </Panel>
  );
};

export default NPSE_AddCoursePanel;
