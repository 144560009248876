import { createAsyncThunk } from '@reduxjs/toolkit';
import { SalaryScaleFormViewModel } from 'src/types/SalaryScaleFormViewModel';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getSalaryScaleFormViewModel,
  getSalaryScaleListItems,
  putAllSalaryScaleListItems,
} from '../../services/salaryScale.service';
import { SalaryScaleListItemModel } from '../../types/SalaryScaleListItemModel';

export const fetchSalaryScaleListItems = createAsyncThunk(
  'input/fetchSalaryScaleListItems',
  async (schoolYear: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      SalaryScaleListItemModel[]
    >(
      thunkAPI,
      getSalaryScaleListItems(schoolYear, { signal: thunkAPI.signal })
    );
  }
);

export const updateAllSalaryScaleListItems = createAsyncThunk(
  'update/allSalaryScaleListItems',
  async (
    params: { schoolYear: string; additionalSalary: string },
    thunkAPI
  ) => {
    const { schoolYear, additionalSalary } = params;
    return await thunkExecuteAndReturnResultOrShowError<
      SalaryScaleListItemModel[]
    >(
      thunkAPI,
      putAllSalaryScaleListItems(schoolYear, additionalSalary, {
        signal: thunkAPI.signal,
      })
    );
  }
);

export const fetchSalaryScaleFormViewModel = createAsyncThunk(
  'input/fetchSalaryScaleFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SalaryScaleFormViewModel>(
      thunkAPI,
      getSalaryScaleFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
