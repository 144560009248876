import FormCheckboxList from 
  '../../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import FormDateSelector from 
  '../../../components/FormInputs/FormDateSelector/FormDateSelector';
import FormRadioList from '../../../components/FormInputs/FormRadioList/FormRadioList';
import { DataEntryMode } from '../../../types/DataEntryMode';
import { LEAManagementFormViewModel } from '../../../types/LEAManagementFormViewModel';
import { PanelType } from '../../../types/PanelType';
import { FormRadioListOrientation } from 
  '../../../types/propTypes/FormRadioListPropTypes';
import { LEAManagementValidationEditSchema } from 
  '../../../types/Validation/LEAManagementValidationEditSchema';
import { LEAManagementValidationSchema } from 
  '../../../types/Validation/LEAManagementValidationSchema';
import { isStateAdmin } from '../../../utilities/userUtilities';
import FormDropdown from '../../FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../FormInputs/FormTextbox/FormTextbox';
import Panel from '../../Panel/Panel';

interface LEAManagementInformationProps {
  dataEntryMode: DataEntryMode;
  formViewModel: LEAManagementFormViewModel;
}

const LEAManagementInformationPanel: React.FC<
  LEAManagementInformationProps
> = ({ dataEntryMode, formViewModel }) => (
  <Panel panelType={PanelType.INFO} heading="LEA Information">
    <div className="field-row">
      <FormTextbox
        displayName="LEA Name"
        field="leaName"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormTextbox
        displayName="Organization Type"
        field="organizationType"
        disabled={true}
      />
      <FormDropdown
        displayName="LEA Type"
        field="leaType"
        options={formViewModel.leaTypeOptions}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
    </div>

    <div className="field-row">
      <FormTextbox
        displayName="LEA Identifier"
        field="leaIdentifier"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormTextbox
        displayName="NCES Identifier"
        field="ncesIdentifier"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormDropdown
        displayName="LEA Operational Status"
        field="leaOperationalStatus"
        options={formViewModel.leaOperationalStatusOptions}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormDateSelector
        displayName="Operational Status Effective Date"
        field="operationalStatusEffectiveDate"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
    </div>

    <div className="field-row">
      <FormRadioList
        displayName="Charter School"
        field="charterSchoolIndicator"
        options={formViewModel.charterSchoolIndicatorOptions}
        optionOrientation={FormRadioListOrientation.Horizontal}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormRadioList
        displayName="Reported Federally"
        field="reportedFederallyIndicator"
        options={formViewModel.reportedFederallyIndicatorOptions}
        optionOrientation={FormRadioListOrientation.Horizontal}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
    </div>

    <div className="field-row">
      <FormDropdown
        displayName="Pre-K Eligibility"
        field="prekEligibility"
        options={formViewModel.prekEligibilityOptions}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormDropdown
        displayName="Pre-K Eligibility Ages for Non-IDEA Students"
        field="prekEligibilityAgesForNonIdeaStudents"
        options={formViewModel.prekEligibilityAgesForNonIdeaStudentsOptions}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
    </div>

    <div className="field-row field-row-grid-1-1-2">
      <FormTextbox
        displayName="Website"
        field="website"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormTextbox
        displayName="Telephone Number"
        field="telephoneNumber"
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
      <FormCheckboxList
        displayName="Grades"
        field="grades"
        options={formViewModel.gradesOptions}
        addAllCheckbox={true}
        classConfig={{ containerClasses: 'grades-container' }}
        validationSchema={
          dataEntryMode === 'new'
            ? LEAManagementValidationSchema
            : LEAManagementValidationEditSchema
        }
        disabled={!isStateAdmin()}
      />
    </div>
  </Panel>
);

export default LEAManagementInformationPanel;
