import {
  ENVIRONMENT_VARIABLE_ERRORS,
  extractFloatEnvVar,
  extractIntegerEnvVar,
  extractStringEnvVar,
} from '../utilities/typeUtilities';

export const TooltipAnchor = 'app-tooltip';

export const getApiBaseUrl = (): string => {
  return extractStringEnvVar('REACT_APP_API_BASE_URL');
};

export const getLogoutTriggerMS = (): number => {
  return extractIntegerEnvVar('REACT_APP_TIMER_TRIGGER_MS');
};

export const getInactivityTimeoutMS = (): number => {
  return extractFloatEnvVar('REACT_APP_INACTIVITY_TIMEOUT_MINUTES') * 60 * 1000;
};

export const getInactivityPromptMS = (): number => {
  const value = extractFloatEnvVar('REACT_APP_INACTIVITY_PROMPT_MINUTES');
  const compare = extractFloatEnvVar('REACT_APP_INACTIVITY_TIMEOUT_MINUTES');

  if (value >= compare) {
    const message = ENVIRONMENT_VARIABLE_ERRORS.LESS_THAN_OR_EQUAL(
      'INACTIVITY_PROMPT_MINUTES',
      'INACTIVITY_TIMEOUT_MINUTES'
    );
    throw new Error(message);
  }

  return value * 60 * 1000;
};

export const getDataGridProLicenseKey = (): string => {
  return extractStringEnvVar('DATA_GRID_PRO_LICENSE_KEY');
};

