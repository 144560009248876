/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import FormCheckbox from '../../../components/FormInputs/FormCheckbox/FormCheckbox';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormInputLabel from '../../../components/FormInputs/FormInputLabel/FormInputLabel';
import { DataDomainPermissionSelectionPropTypes } from '../../../types/propTypes/DataDomainPermissionSelectionPropTypes';
import { FormDropdownChangeEventConfig } from '../../../types/propTypes/FormDropdownPropTypes';
import { UserEditFormModel } from '../../../types/UserEditModel';
import { getRoleDisplayText } from '../../../utilities/userUtilities';
import './DataDomainPermissionSelection.css';

const DataDomainPermissionSelection = ({
  dataDomainPermissionMetadata,
  schoolOptions,
}: DataDomainPermissionSelectionPropTypes): ReactElement => {
  const { values } = useFormikContext();

  const isDisabled = (): boolean => {
    return (
      (values as UserEditFormModel).permissionsByDataDomain[
        dataDomainPermissionMetadata.name
      ].schoolIds.length === 0
    );
  };

  // If dropdown is cleared out, then unselect certification checkboxes
  const handleDropdownChanges = async (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    if ((props.value as []).length === 0 && props.setValueHook) {
      props.setValueHook(
        `permissionsByDataDomain.${dataDomainPermissionMetadata.name}.canCertifyDistrict`,
        false
      );
      props.setValueHook(
        `permissionsByDataDomain.${dataDomainPermissionMetadata.name}.canCertifySchools`,
        false
      );
      props.setValueHook(
        `permissionsByDataDomain.${dataDomainPermissionMetadata.name}.msisIdRequestAccess`,
        false
      );
      props.setValueHook(
        `permissionsByDataDomain.${dataDomainPermissionMetadata.name}.studentOwnershipAccess`,
        false
      );
    }
  };

  return (
    <div
      className="data-domain-permission-selection"
      key={dataDomainPermissionMetadata.name}
      data-testid={`input-${dataDomainPermissionMetadata.name}`}
    >
      <div className="label-with-checkboxes">
        <FormInputLabel
          field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}`}
          displayName={getRoleDisplayText(dataDomainPermissionMetadata.name)}
          classes="permission-name"
        />
        {dataDomainPermissionMetadata.canCertifyDistrict && (
          <FormCheckbox
            displayName="Can Certify District"
            field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}.canCertifyDistrict`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {dataDomainPermissionMetadata.canCertifySchools && (
          <FormCheckbox
            displayName="Can Certify School(s)"
            field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}.canCertifySchools`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {dataDomainPermissionMetadata.msisIdRequestAccess && (
          <FormCheckbox
            displayName="MSIS ID Request"
            field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}.msisIdRequestAccess`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
        {dataDomainPermissionMetadata.studentOwnershipAccess && (
          <FormCheckbox
            displayName="Student Ownership"
            field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}.studentOwnershipAccess`}
            disabled={isDisabled()}
            disableLabelOnCheckboxDisabled={true}
          ></FormCheckbox>
        )}
      </div>
      <FormDropdown
        field={`permissionsByDataDomain.${dataDomainPermissionMetadata.name}.schoolIds`}
        options={schoolOptions}
        isMulti={true}
        onChangeEvent={handleDropdownChanges}
      />
    </div>
  );
};

export default DataDomainPermissionSelection;
