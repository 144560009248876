/* eslint-disable max-len */
import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmationModalContext } from '../../../../hooks/useConfirmationModalContext';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectNonPublicStudentScheduleListItems } from '../../../../redux/slices/nonPublicStudentSlice';
import { fetchNonPublicStudentScheduleByNpsId } from '../../../../redux/thunks/nonPublicStudentThunks';
import { isValidCode } from '../../../../services/base.service';
import { deleteSchedule } from '../../../../services/nonPublicStudent.service';
import { appRoutePaths } from '../../../../services/route.service';
import { canUserAccessRoute } from '../../../../services/security.service';
import { ApiResponseModel } from '../../../../types/ApiResponseModel';
import { NonPublicStudentScheduleListItemModel } from '../../../../types/NonPublicStudentScheduleListItemModel';
import { PanelType } from '../../../../types/PanelType';
import ActionButton from '../../../ActionButton/ActionButton';
import NavigationButton from '../../../NavigationButton/NavigationButton';
import Panel from '../../../Panel/Panel';
import './NPSE_StudentScheduleList.css';

const NPSE_StudentScheduleList = (): ReactElement => {
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();
  const queryParams = useParams();
  const npseId = queryParams['nonPublicStudentId'];
  const navigate = useNavigate();

  const schedule: NonPublicStudentScheduleListItemModel[] = useAppSelector(
    selectNonPublicStudentScheduleListItems
  );

  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.NonPublicStudentEntryScheduleInput(npseId, id));
  };

  const handleDelete = async (
    id: string,
    courseTitle: string
  ): Promise<void> => {
    confirmContext.setOptions({
      title: `Delete Schedule?`,
      content: (
        <div className="confirm--message">
          If this student was enrolled in this course, please change the Status
          to Inactive. Are you sure you want to delete the schedule for &quot;
          {courseTitle}&quot;?
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        const response = await deleteSchedule(id);
        if (isValidCode(response.status)) {
          /* istanbul ignore next */
          const parentId = npseId || '';
          dispatch(fetchNonPublicStudentScheduleByNpsId(parentId));
        }
        return response;
      },
    });

    await confirmContext.showConfirmation();
  };

  return (
    <div className="npse-schedule">
      <Panel panelType={PanelType.INFO} heading="Student Schedule">
        <div className="content-outer-container">
          <div className="content-inner-container">
            <div className="subheading-and-button-container">
              <h4 data-testid="component-heading-text" />
              <NavigationButton
                route={appRoutePaths.NonPublicStudentEntryScheduleInput(
                  npseId,
                  'new'
                )}
                classes="button no-wrap-text new-button"
                tooltipText="Add New Student Schedule"
                visibleOverride={canUserAccessRoute(
                  appRoutePaths.NonPublicStudentEntryScheduleInput()
                )}
              >
                <>Add New</>
              </NavigationButton>
            </div>
            <div className="table-container">
              <table cellSpacing={0} className="page-view-table">
                <thead>
                  <tr>
                    <th>School Year</th>
                    <th>Course Code</th>
                    <th>School Identifier</th>
                    <th>Teacher Name</th>
                    <th>Course Section</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.length > 0 &&
                    schedule.map((row, index) => (
                      <tr
                        key={`schedule-row-${index}`}
                        data-testid={`schedule-row-${index}`}
                      >
                        <td>{row.schoolYear}</td>
                        <td>{row.courseTitle}</td>
                        <td>{row.schoolName}</td>
                        <td>{row.staffMemberName}</td>
                        <td>{row.courseSectionDescription}</td>
                        <td>{row.status}</td>
                        <td>
                          <div className="action--column">
                            <ActionButton
                              onClick={async () => {
                                handleEdit(row.id);
                              }}
                              dataTestId={`edit-button-${row.id}`}
                              classes="no-button-padding"
                              tooltipText="View/Edit Schedule"
                            >
                              <img src={'/images/icon-edit.svg'} />
                            </ActionButton>
                            <ActionButton
                              onClick={async () => {
                                await handleDelete(row.id, row.courseTitle);
                              }}
                              dataTestId={`delete-button-${row.id}`}
                              classes="no-button-padding"
                              tooltipText="Delete Schedule"
                            >
                              <img src={'/images/icon-unassign.svg'} />
                            </ActionButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default NPSE_StudentScheduleList;
