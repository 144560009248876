/* eslint-disable max-len */
import { ReactElement } from 'react';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppSelector } from '../../../../redux/hooks';
import {
  clearSchoolManagement,
  saveSchoolManagement,
  selectSchoolManagementDataEntryMode,
  selectSchoolManagementFormViewModel,
  selectSchoolManagementLoadingFormViewModel,
  selectSchoolManagementLoadingSchoolManagement,
  selectSchoolManagementSchoolManagement,
  setSchoolManagementDataEntryMode,
} from '../../../../redux/slices/schoolManagementSlice';
import {
  fetchSchoolManagementById,
  fetchSchoolManagementFormViewModel,
} from '../../../../redux/thunks/schoolManagementThunks';
import { getCurrentUserAccess } from '../../../../services/base.service';
import { appRoutePaths } from '../../../../services/route.service';
import {
  createSchoolManagement,
  updateSchoolManagement,
} from '../../../../services/schoolManagement.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_DISTRICT_SCHOOL } from '../../../../types/NavigationTabs';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { SchoolManagementFormViewModel } from '../../../../types/SchoolManagementFormViewModel';
import { SchoolManagementModel } from '../../../../types/SchoolManagementModel';
import { SchoolManagementValidationSchema } from '../../../../types/Validation/SchoolManagementValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';
import './SchoolManagementInput.css';
import SchoolManagementMailingAddressPanel from '../../../../components/FormLayouts/SchoolManagement/SchoolManagementMailingAddressPanel';
import SchoolManagementPhysicalAddressPanel from '../../../../components/FormLayouts/SchoolManagement/SchoolManagementPhysicalAddressPanel';
import SchoolManagementSchoolInformationPanel from '../../../../components/FormLayouts/SchoolManagement/SchoolManagementSchoolInformationPanel';
const SchoolManagementInput = (): ReactElement => {
  const schoolManagementModel: SchoolManagementModel = useAppSelector(
    selectSchoolManagementSchoolManagement
  );
  const formViewModel: SchoolManagementFormViewModel = useAppSelector(
    selectSchoolManagementFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectSchoolManagementLoadingFormViewModel
  );
  const isLoadingSchoolManagementModel = useAppSelector(
    selectSchoolManagementLoadingSchoolManagement
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectSchoolManagementDataEntryMode
  );
  const isLoading = isLoadingSchoolManagementModel || isLoadingFormViewModel;
  const supportedNonGenericErrors = [
    {
      statusCode: 400,
    },
    {
      statusCode: 409,
      howToFix:
        'The provided School Management ID is already in the system. Use another value, or return to the list and edit the existing School Management.',
    },
  ];

  /* istanbul ignore next */
  const getDistrictDisplay = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return schoolManagementModel.schoolIdentifierLeaPart;
    } else {
      const user = getCurrentUserAccess();
      const districtId = user?.currentDistrict?.districtId || '';

      return `${districtId}`;
    }
  };

  /* istanbul ignore next */
  const getDistrictType = (): string => {
    const user = getCurrentUserAccess();
    const districtType = user?.currentDistrict?.type || '';

    const charterSchoolIndicator =
      districtType === 'Independent Charter District' ? 'Yes' : 'No';
    return `${charterSchoolIndicator}`;
  };

  /* istanbul ignore next */
  const getDistrictName = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return schoolManagementModel.leaName;
    } else {
      const user = getCurrentUserAccess();
      const districtName = user?.currentDistrict?.name || '';

      return `${districtName}`;
    }
  };

  const intitalValues = {
    ...schoolManagementModel,
    leaIdentifier: getDistrictDisplay(),
    leaName: getDistrictName(),
    schoolIdentifierLeaPart: getDistrictDisplay(),
    charterSchool: getDistrictType(),
    //    charterSchoolType: getDistrictType() === 'No' ? 'Not a Charter School' : '',
  };

  return (
    <ManualInputPage<SchoolManagementModel, SchoolManagementFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'school-management-input-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_DISTRICT_SCHOOL)}
      classes={{ pageClass: 'data-input--school-management--input' }}
      parameterOptions={{
        routeParameterName: 'schoolIdentifier',
        identityName: schoolManagementModel.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.SchoolManagementList,
        cancelRoute: appRoutePaths.SchoolManagementList,
        createSuccessRoute: appRoutePaths.SchoolManagementInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchSchoolManagementById,
        loadFormDetail: fetchSchoolManagementFormViewModel,
        setEntryMode: setSchoolManagementDataEntryMode,
        setData: saveSchoolManagement,
        clearData: clearSchoolManagement,
        onCreate: createSchoolManagement,
        onUpdate: updateSchoolManagement,
      }}
      formDetails={{
        validationSchema: SchoolManagementValidationSchema,
        initialFormValues: intitalValues,
        isFormModelEmpty: !formViewModel.gradeOptions?.length,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'School Management',
        pageHeader: 'LEA & School Info',
        supportedNonGenericErrors: supportedNonGenericErrors,
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <div data-testid="school-management-input-child">
        <SchoolManagementSchoolInformationPanel />
        <SchoolManagementMailingAddressPanel />
        <SchoolManagementPhysicalAddressPanel />
      </div>
    </ManualInputPage>
  );
};

export default SchoolManagementInput;
