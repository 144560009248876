/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { SalaryScaleFormViewModel } from '../../types/SalaryScaleFormViewModel';
import {
  SalaryScaleInputModel,
  SalaryScaleListItemModel,
} from '../../types/SalaryScaleListItemModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchSalaryScaleFormViewModel,
  fetchSalaryScaleListItems,
  updateAllSalaryScaleListItems,
} from '../thunks/salaryScaleThunks';

interface SalaryScaleState {
  schoolYear: string;
  updateSalary: SalaryScaleInputModel;
  loadingListItems: boolean;
  listItems: SalaryScaleListItemModel[];
  dataEntryMode: DataEntryMode;
  updatedListItems: SalaryScaleListItemModel[];
  loadingFormViewModel: boolean;
  formViewModel: SalaryScaleFormViewModel;
}

export const defaultListState: SalaryScaleListItemModel[] = [];

export const defaultSalaryScaleState: SalaryScaleListItemModel = {
  id: '',
  schoolYear: '',
  yearsOfExperience: '',
  aAssociateDegreeSalary: '',
  aaBachelorDegreeSalary: '',
  aaaMasterDegreeSalary: '',
  aaaaPhDDegreeSalary: '',
};

export const defaultAdditionalSalaryState: SalaryScaleInputModel = {
  schoolYear: '2024-2025',
  additionalSalary: '$0.00',
};

export const defaultFormState: SalaryScaleFormViewModel = {
  historicalSchoolYearOptions: [],
};

export const dataEntryMode = DataEntryMode.NEW;

export const initialState: SalaryScaleState = {
  schoolYear: '',
  updateSalary: defaultAdditionalSalaryState,
  loadingListItems: false,
  listItems: defaultListState,
  dataEntryMode: dataEntryMode,
  updatedListItems: defaultListState,
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
};

export const salaryScaleSlice = createSlice({
  name: 'salaryScale',
  initialState,
  reducers: {
    setSchoolYearFilter: (state, action: PayloadAction<string>) => {
      state.schoolYear = action.payload;
    },
    setSalaryScaleDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
    setAdditionalSalary: (state, action: PayloadAction<string>) => {
      state.updateSalary.additionalSalary = action.payload;
    },
    setDefaultAdditionalSalary: (state) => {
      state.updateSalary.additionalSalary = '$0.0';
    },
    setUpdatedListItems: (
      state,
      action: PayloadAction<SalaryScaleListItemModel>
    ) => {
      const data = action.payload;
      state.updatedListItems.push(data);
    },
    setDefaultUpdatedListItems: (state) => {
      state.updatedListItems = [];
    },
    setIsListItemLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingListItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchSalaryScaleListItems
    builder.addCase(fetchSalaryScaleListItems.pending, (state) => {
      state.listItems = defaultListState;
      state.loadingListItems = true;
    });
    builder.addCase(fetchSalaryScaleListItems.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.listItems = action.payload;
      }
      state.loadingListItems = false;
    });
    builder.addCase(fetchSalaryScaleListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    //updateAllSalaryScaleListItems
    builder.addCase(updateAllSalaryScaleListItems.pending, (state) => {
      state.loadingListItems = true;
    });
    builder.addCase(
      updateAllSalaryScaleListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
      }
    );
    builder.addCase(updateAllSalaryScaleListItems.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingListItems = false;
      }
    });

    // fetchSalaryScaleFormViewModel
    builder.addCase(fetchSalaryScaleFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchSalaryScaleFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
          state.schoolYear =
            action.payload.historicalSchoolYearOptions[1].value;
          state.updateSalary.schoolYear =
            action.payload.historicalSchoolYearOptions[1].value;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(fetchSalaryScaleFormViewModel.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingFormViewModel = false;
      }
    });
  },
});

//export reducers
export const {
  setSchoolYearFilter,
  setSalaryScaleDataEntryMode,
  setAdditionalSalary,
  setDefaultAdditionalSalary,
  setUpdatedListItems,
  setDefaultUpdatedListItems,
  setIsListItemLoading,
} = salaryScaleSlice.actions;

// listItems
export const selectSalaryScaleLoadingListItems = (state: RootState): boolean =>
  state.salaryScale.loadingListItems;
export const selectSalaryScaleListItems = (
  state: RootState
): SalaryScaleListItemModel[] => state.salaryScale.listItems;
export const selectDefaultSalaryScaleInput = (
  state: RootState
): SalaryScaleInputModel => state.salaryScale.updateSalary;

export const selectSchoolYear = (state: RootState): string =>
  state.salaryScale.schoolYear;
export const selectAdditionalSalary = (state: RootState): string =>
  state.salaryScale.updateSalary.additionalSalary;
export const selectUpdatedListItems = (
  state: RootState
): SalaryScaleListItemModel[] => state.salaryScale.updatedListItems;

// dataEntryMode
export const selectSalaryScaleDataEntryMode = (
  state: RootState
): DataEntryMode => state.salaryScale.dataEntryMode;

// formViewModel
export const selectSalaryScaleLoadingFormViewModel = (
  state: RootState
): boolean => state.salaryScale.loadingFormViewModel;
export const selectSalaryScaleFormViewModel = (
  state: RootState
): SalaryScaleFormViewModel => state.salaryScale.formViewModel;

export default salaryScaleSlice.reducer;
