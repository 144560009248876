import { NonPublicStudentListItemModel } from './NonPublicStudentListItemModel';
import { SelectOption } from './SelectOption';

export interface SchoolYearListModel {
  nonPublicStudents: NonPublicStudentListItemModel[];
}

export const defaultSchoolYear = (): string => {
  let year = new Date().getFullYear();
  const month = new Date().getMonth();
  if (month > 7) year = year + 1;
  return `${year - 1}-${year}`;
}; //= '2023-2024';
export const getSchoolYearList = (): SelectOption[] => {
  const list: SelectOption[] = [];
  let year = new Date().getFullYear();
  const month = new Date().getMonth();
  if (month > 7) year = year + 1;
  const year1 = `${year - 1}-${year}`;
  const year2 = `${year - 2}-${year - 1}`;
  list.push({ text: year1, value: year1 });
  list.push({ text: year2, value: year2 });

  return list;
};

