import { useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import FormDropdown from '../../../components/FormInputs/FormDropdown/FormDropdown';
import FormTextbox from '../../../components/FormInputs/FormTextbox/FormTextbox';
import Panel from '../../../components/Panel/Panel';
import { useAppSelector } from '../../../redux/hooks';
import { selectSchoolManagementFormViewModel } from 
  '../../../redux/slices/schoolManagementSlice';
import { PanelType } from '../../../types/PanelType';
import { FormDropdownChangeEventConfig } from 
  '../../../types/propTypes/FormDropdownPropTypes';
import { FormTextboxChangeEventConfig } from 
  '../../../types/propTypes/FormTextboxPropTypes';
import { SchoolManagementFormViewModel } from 
  '../../../types/SchoolManagementFormViewModel';
import { SchoolManagementModel } from '../../../types/SchoolManagementModel';
import { SchoolManagementValidationSchema } from 
  '../../../types/Validation/SchoolManagementValidationSchema';
import {
  getCountyAnsiCodeFromOptions,
  isStateAdmin,
} from '../../../utilities/userUtilities';

const SchoolManagementMailingAddressPanel = (): ReactElement => {
  const { values, setFieldValue } = useFormikContext<SchoolManagementModel>();

  const DEFAULT_STATE = 'Mississippi';

  const [areMailingAddressFieldsRequired, setAreMailingAddressFieldsRequired] =
    useState(false);

  const updateMailingAddressRequiredFlag = async (
    config: FormTextboxChangeEventConfig
  ): Promise<void> => {
    await setFieldValue(config.field, config.value);
    const updatedValues = {
      ...values,
      [config.field]: config.value,
    };
    const isRequired =
      updatedValues.mailingAddressStreetNumberAndName === '' &&
      updatedValues.mailingCity === '' &&
      updatedValues.mailingState === '' &&
      updatedValues.mailingZipCode === ''
        ? false
        : true;
    setAreMailingAddressFieldsRequired(isRequired);
  };

  const getRequiredMailingAddressLabel = (): string => {
    return areMailingAddressFieldsRequired ? 'forced-required-label' : '';
  };

  const formViewModel: SchoolManagementFormViewModel = useAppSelector(
    selectSchoolManagementFormViewModel
  );
  const [isShowMailingCounty, setIsShowMailingCounty] = useState(false);

  const updateMailingCountyAnsiCode = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    await setFieldValue(config.field, config.value);

    const mailingCountyAnsiCode = getCountyAnsiCodeFromOptions(
      formViewModel.countyAnsiCodeOptions,
      config
    );
    /* istanbul ignore next */
    if (config.setValueHook && mailingCountyAnsiCode) {
      config.setValueHook('mailingCountyAnsiCode', mailingCountyAnsiCode);
    }
  };

  const updateShowMailingCounty = async (
    config: FormDropdownChangeEventConfig
  ): Promise<void> => {
    const showCounty = config.value === DEFAULT_STATE ? true : false;
    setIsShowMailingCounty(showCounty);
    setAreMailingAddressFieldsRequired(true);
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Mailing Address">
      <div className="field-row">
        <FormTextbox
          displayName="Address Street Number and Name"
          field="mailingAddressStreetNumberAndName"
          classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateMailingAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Apt., Suite, Unit, Building, Floor, Etc."
          field="mailingAptSuiteBuildingFloorNumber"
          validationSchema={SchoolManagementValidationSchema}
          disabled={!isStateAdmin()}
        />
      </div>
      <div className="field-row">
        <FormTextbox
          displayName="City"
          field="mailingCity"
          classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateMailingAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
        <FormDropdown
          displayName="State"
          field="mailingState"
          options={formViewModel.stateOptions}
          classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateShowMailingCounty}
          disabled={!isStateAdmin()}
        />
        <FormTextbox
          displayName="Zip Code"
          field="mailingZipCode"
          classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
          validationSchema={SchoolManagementValidationSchema}
          onChangeEvent={updateMailingAddressRequiredFlag}
          disabled={!isStateAdmin()}
        />
      </div>
      {isShowMailingCounty && (
        <div className="field-row">
          <FormDropdown
            displayName="County"
            field="mailingCounty"
            options={formViewModel.countyOptions}
            classConfig={{ labelClasses: getRequiredMailingAddressLabel() }}
            validationSchema={SchoolManagementValidationSchema}
            onChangeEvent={updateMailingCountyAnsiCode}
            disabled={!isStateAdmin()}
          />
          <FormTextbox
            displayName="County ANSI Code"
            field="mailingCountyAnsiCode"
            disabled={true}
          />
        </div>
      )}
    </Panel>
  );
};

export default SchoolManagementMailingAddressPanel;
