import { DataDomainPermission } from '../types/DataDomainPermission';
import {
  DataDomainPermissionDetails,
  UserDetailsFormModel,
  UserDetailsViewModel,
} from '../types/UserDetailsViewModel';

export const mapToFormModel = (
  details: UserDetailsViewModel
): UserDetailsFormModel => {
  const dataDomainPermissions: DataDomainPermissionDetails[] = [];

  const getAdditionalPermissions = (
    permission: DataDomainPermission
  ): string => {
    const items: string[] = [];
    if (permission.canCertifyDistrict) {
      items.push('Can Certify District');
    }
    if (permission.canCertifySchools) {
      items.push('Can Certify Schools');
    }
    if (permission.msisIdRequestAccess) {
      items.push('MSIS ID Request');
    }
    if (permission.studentOwnershipAccess) {
      items.push('Student Ownership');
    }
    return items.join(', ');
  };

  for (const [key, value] of Object.entries(
    details.permissionsByDataDomainForCurrentDistrict
  )) {
    const schoolList = value.schools
      .map((school) => `${school.name} (${school.schoolId})`)
      .join(', ');
    dataDomainPermissions.push({
      role: key,
      schools: schoolList,
      additionalPermissions: getAdditionalPermissions(value),
    });
  }

  return {
    ...details,
    dataDomainPermissionsForCurrentDistrict: dataDomainPermissions,
  };
};
