/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectSummerActivityListItems,
  selectSummerActivityLoadingListItems,
} from '../../../../redux/slices/summerActivitySlice';
import { appRoutePaths } from '../../../../services/route.service';
import StudentPage from '../StudentPage';

import { fetchSummerActivityListItems } from '../../../../redux/thunks/summerActivityThunks';
import { TooltipAnchor } from '../../../../services/app.service';
import { abortPromiseOnUnmount } from '../../../../services/base.service';
import { SummerActivityListItemModel } from '../../../../types/SummerActivityListItemModel';
import { formatToDateString } from '../../../../utilities/dateUtilities';
import './SummerActivityList.css';

const SummerActivityList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectSummerActivityLoadingListItems);
  const listItems: SummerActivityListItemModel[] = useAppSelector(
    selectSummerActivityListItems
  );

  useEffect(() => {
    const promise: unknown = dispatch(fetchSummerActivityListItems());
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch]);

  const handleEdit = (id: string): void => {
    navigate(appRoutePaths.SummerActivityInput(id));
  };

  return (
    <StudentPage
      pageClass="data-input--student--summer-activity--list"
      isLoading={isLoading}
      loadingDataId={'summer-activity-list-loader'}
    >
      <div className="content-outer-container">
        <div className="content-inner-container">
          <div className="subheading-and-button-container">
            <h4 data-testid={'component-heading-text'}>Summer Activity</h4>
          </div>
          <div className="table-container">
            <table cellSpacing={0} className="page-view-table">
              <thead>
                <tr>
                  <th>MSIS ID</th>
                  <th>Student Name</th>
                  <th>Birthdate</th>
                  <th>Grade Level</th>
                  <th>Reason Description</th>
                  <th>Verified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listItems?.length > 0 &&
                  listItems.map((summerActivity) => (
                    <tr
                      key={`${summerActivity.id}`}
                      data-testid={summerActivity.id}
                    >
                      <td>{summerActivity.msisId}</td>
                      <td>
                        {summerActivity.lastName +
                          ', ' +
                          summerActivity.firstName}
                      </td>
                      <td>{formatToDateString(summerActivity.birthDate)}</td>
                      <td>{summerActivity.gradeLevel}</td>
                      <td>{summerActivity.reasonDescription}</td>
                      <td>{summerActivity.verified}</td>
                      <td>
                        <div className="action--column">
                          <button
                            data-testid={`edit-button-${summerActivity.id}`}
                            aria-label="Edit"
                            onClick={() => handleEdit(summerActivity.id)}
                            data-tooltip-id={TooltipAnchor}
                            data-tooltip-content={'Edit/View Summer Activity'}
                          >
                            <img src={'/images/icon-edit.svg'} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </StudentPage>
  );
};

export default SummerActivityList;
