/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { selectNonPublicStudentFormViewModel } from '../../../../redux/slices/nonPublicStudentSlice';
import { NonPublicStudentEntryModel } from '../../../../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../../../../types/NonPublicStudentFormViewModel';
import { PanelType } from '../../../../types/PanelType';
import { FormDropdownChangeEventConfig } from '../../../../types/propTypes/FormDropdownPropTypes';
import { FormRadioListOrientation } from '../../../../types/propTypes/FormRadioListPropTypes';
import { SelectOption } from '../../../../types/SelectOption';
import { NPSE_SPEDOnlyValidationSchema } from '../../../../types/Validation/NonPublicStudentEntryValidationSchema';
import {
  buildEducationEnvironmentOptions,
  isEducationEnvironmentDisabled,
} from '../../../../utilities/manualInputUtilities';
import FormDateSelector from '../../../FormInputs/FormDateSelector/FormDateSelector';
import FormDropdown from '../../../FormInputs/FormDropdown/FormDropdown';
import FormRadioList from '../../../FormInputs/FormRadioList/FormRadioList';
import Panel from '../../../Panel/Panel';
import './NPSE_SPEDPanel.css';

const NPSE_SPEDPanel = (): ReactElement => {
  const { values, setFieldValue } =
    useFormikContext<NonPublicStudentEntryModel>();

  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );

  const getSchoolOptions = (districtId: string): SelectOption[] => {
    const matchedDistrict =
      formViewModel.districts &&
      formViewModel.districts.filter((d) => d.districtId === districtId);

    /* istanbul ignore next */
    if (matchedDistrict && matchedDistrict.length === 0) {
      return [];
    }

    return matchedDistrict[0].schools.map((x) => ({
      text: x.name,
      value: x.schoolId,
    }));
  };

  const [schoolOptions, setSchoolOptions] = useState<SelectOption[]>(
    getSchoolOptions(values.responsibleDistrictAccountabilityId)
  );

  const isProjectedDiplomaRequired = (): boolean => {
    return ['09', '10', '11', '12', 'AE'].includes(values.gradeLevel);
  };

  const getDistrictOptions = (): SelectOption[] => {
    return formViewModel.districts.map((x) => ({
      text: x.name,
      value: x.districtId,
    }));
  };

  const handleDistrictChange = (
    props: FormDropdownChangeEventConfig
  ): Promise<void> => {
    setFieldValue('responsibleSchoolAccountabilityId', '');
    setSchoolOptions(getSchoolOptions(props.value as string));

    return Promise.resolve();
  };

  return (
    <Panel panelType={PanelType.INFO} heading="Special Education">
      <div className="field-row field-row-sped-row-start">
        <FormDateSelector
          displayName="Eligibility Date"
          field="eligibilityDeterminationDate"
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormDateSelector
          displayName="IEP Start Date"
          field="iepStartDate"
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormDropdown
          displayName="Projected HS Diploma Type"
          field="projectedHSDiplomaType"
          options={formViewModel.projectedHSDiplomaTypeOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
          classConfig={{
            labelClasses: isProjectedDiplomaRequired()
              ? 'forced-required-label'
              : '',
          }}
        />
        <FormDateSelector
          displayName="Services Exit Date"
          field="programParticipationExitDate"
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
      </div>

      <div className="field-row field-row-sped-alt-rows">
        <FormDropdown
          displayName="Primary Disability"
          field="primaryDisabilityType"
          options={formViewModel.primaryDisabilityTypeOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormDropdown
          displayName="Secondary Disability"
          field="secondaryDisabilityType"
          options={formViewModel.secondaryDisabilityTypeOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormRadioList
          displayName="SCD"
          field="significantCognitiveDisabilityIndicator"
          optionOrientation={FormRadioListOrientation.Horizontal}
          options={formViewModel.significantCognitiveDisabilityIndicatorOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
      </div>

      <div className="field-row field-row-sped-alt-rows">
        <FormDropdown
          displayName="Education Environment"
          field="ideaEducationalEnvironment"
          options={buildEducationEnvironmentOptions(
            formViewModel.ideaEducationalEnvironments,
            values.ageEvaluationYear,
            values.birthDate,
            values.gradeLevel
          )}
          disabled={isEducationEnvironmentDisabled(
            values.ageEvaluationYear,
            values.birthDate
          )}
          classConfig={{
            labelClasses: !isEducationEnvironmentDisabled(
              values.ageEvaluationYear,
              values.birthDate
            )
              ? 'forced-required-label'
              : '',
          }}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormDropdown
          displayName="Related Services"
          field="relatedServices"
          isMulti={true}
          options={formViewModel.relatedServicesOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
      </div>

      <div className="field-row field-row-sped-alt-rows">
        <FormDropdown
          displayName="Responsible District Accountability"
          field="responsibleDistrictAccountabilityId"
          options={getDistrictOptions()}
          onChangeEvent={handleDistrictChange}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
        <FormDropdown
          displayName="Responsible School Accountability"
          field="responsibleSchoolAccountabilityId"
          options={schoolOptions}
          validationSchema={NPSE_SPEDOnlyValidationSchema}
        />
      </div>
    </Panel>
  );
};

export default NPSE_SPEDPanel;
