import { createAsyncThunk } from '@reduxjs/toolkit';
import { thunkExecuteAndReturnResultOrShowError } from '../../services/base.service';
import {
  getSummerActivityById,
  getSummerActivityFormViewModel,
  getSummerActivityListItems,
} from '../../services/SummerActivity.service';
import { SummerActivityFormViewModel } from '../../types/SummerActivityFormViewModel';
import { SummerActivityListItemModel } from '../../types/SummerActivityListItemModel';
import { SummerActivityModel } from '../../types/SummerActivityModel';

export const fetchSummerActivityListItems = createAsyncThunk(
  'input/fetchSummerActivityListItems',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<
      SummerActivityListItemModel[]
    >(thunkAPI, getSummerActivityListItems({ signal: thunkAPI.signal }));
  }
);

export const fetchSummerActivityById = createAsyncThunk(
  'input/fetchSummerActivityById',
  async (summerActivityId: string, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SummerActivityModel>(
      thunkAPI,
      getSummerActivityById(summerActivityId, { signal: thunkAPI.signal })
    );
  }
);

export const fetchSummerActivityFormViewModel = createAsyncThunk(
  'input/fetchSummerActivityFormViewModel',
  async (_, thunkAPI) => {
    return await thunkExecuteAndReturnResultOrShowError<SummerActivityFormViewModel>(
      thunkAPI,
      getSummerActivityFormViewModel({ signal: thunkAPI.signal })
    );
  }
);
