import { isEducationEnvironmentDisabled } from '../../utilities/manualInputUtilities';
import { NPSESchoolType } from '../NonPublicStudentFormViewModel';
import { VALIDATION_MESSAGE } from '../ValidationMessage';
import yup from './CustomValidation/yup-extended';

export const NonPublicStudentEntryValidationSchema = yup.object().shape(
  {
    studentIdentifier: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .mustBeNumber()
      .min(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9))
      .max(9, VALIDATION_MESSAGE.EXACT_NUMBER_LENGTH(9)),
    lastOrSurname: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    firstName: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    birthDate: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    schoolType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    sex: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    hispanicOrLatinoEthnicity: yup
      .string()
      .required(VALIDATION_MESSAGE.REQUIRED),
    specialEducation: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    englishLearnerStatus: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    enrollmentEntryDate: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    entryType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    enrollmentStatus: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
    gradeLevel: yup.string().required(VALIDATION_MESSAGE.REQUIRED),

    // Used for UI purposes:
    hasMatch: yup.boolean().required(VALIDATION_MESSAGE.REQUIRED).isTrue(),
    ageEvaluationYear: yup.string(),

    singleParentOrSinglePregnantWomanStatus: yup.string().when('schoolType', {
      is: NPSESchoolType.CTE,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    cteaeDisplacedHomemakerIndicator: yup.string().when('schoolType', {
      is: NPSESchoolType.CTE,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    perkinsPostProgramPlacementIndicator: yup.string().when('schoolType', {
      is: NPSESchoolType.CTE,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),

    eligibilityDeterminationDate: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    iepStartDate: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    projectedHSDiplomaType: yup.string().when(['schoolType', 'gradeLevel'], {
      is: (schoolType: string, gradeLevel: string) =>
        schoolType === NPSESchoolType.SPED &&
        ['09', '10', '11', '12', 'AE'].includes(gradeLevel),
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    primaryDisabilityType: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    significantCognitiveDisabilityIndicator: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    ideaEducationalEnvironment: yup
      .string()
      .when(['schoolType', 'birthDate', 'ageEvaluationYear'], {
        is: (
          schoolType: string,
          birthDate: string,
          ageEvaluationYear: string
        ): boolean => {
          return (
            schoolType === NPSESchoolType.SPED &&
            ageEvaluationYear !== undefined &&
            ageEvaluationYear.length > 0 &&
            birthDate !== undefined &&
            birthDate.length > 0 &&
            !isEducationEnvironmentDisabled(ageEvaluationYear, birthDate)
          );
        },
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
    relatedServices: yup.array().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup
        .array()
        .required(VALIDATION_MESSAGE.REQUIRED)
        .min(1, VALIDATION_MESSAGE.MUST_SELECT_AT_LEAST(1)),
      otherwise: yup.array().notRequired(),
    }),
    responsibleDistrictAccountabilityId: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    responsibleSchoolAccountabilityId: yup.string().when('schoolType', {
      is: NPSESchoolType.SPED,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),

    outcomeAEntryScore: yup.string(),
    outcomeAEntryScoreDate: yup.string().when('outcomeAEntryScore', {
      is: (entryScore: string) => entryScore && entryScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeAExitScore: yup.string(),
    outcomeAExitScoreDate: yup.string().when('outcomeAExitScore', {
      is: (exitScore: string) => exitScore && exitScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeAProgress: yup
      .string()
      .when(['outcomeAEntryScore', 'outcomeAExitScore'], {
        is: (entryScore: string, exitScore: string) =>
          entryScore &&
          entryScore.length > 0 &&
          exitScore &&
          exitScore.length > 0,
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),

    outcomeBEntryScore: yup.string(),
    outcomeBEntryScoreDate: yup.string().when('outcomeBEntryScore', {
      is: (entryScore: string) => entryScore && entryScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeBExitScore: yup.string(),
    outcomeBExitScoreDate: yup.string().when('outcomeBExitScore', {
      is: (exitScore: string) => exitScore && exitScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeBProgress: yup
      .string()
      .when(['outcomeBEntryScore', 'outcomeBExitScore'], {
        is: (entryScore: string, exitScore: string) =>
          entryScore &&
          entryScore.length > 0 &&
          exitScore &&
          exitScore.length > 0,
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),

    outcomeCEntryScore: yup.string(),
    outcomeCEntryScoreDate: yup.string().when('outcomeCEntryScore', {
      is: (entryScore: string) => entryScore && entryScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeCExitScore: yup.string(),
    outcomeCExitScoreDate: yup.string().when('outcomeCExitScore', {
      is: (exitScore: string) => exitScore && exitScore.length > 0,
      then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
      otherwise: yup.string().notRequired(),
    }),
    outcomeCProgress: yup
      .string()
      .when(['outcomeCEntryScore', 'outcomeCExitScore'], {
        is: (entryScore: string, exitScore: string) =>
          entryScore &&
          entryScore.length > 0 &&
          exitScore &&
          exitScore.length > 0,
        then: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
        otherwise: yup.string().notRequired(),
      }),
  },
  [
    ['schoolType', 'relatedServices'],
    ['schoolType', 'ideaEducationalEnvironment'],
    ['gradeLevel', 'relatedServices'],
    ['gradeLevel', 'ideaEducationalEnvironment'],
    ['ageEvaluationYear', 'ideaEducationalEnvironment'],
    ['birthDate', 'ideaEducationalEnvironment'],
    ['outcomeAEntryScore', 'outcomeAEntryScoreDate'],
    ['outcomeAExitScore', 'outcomeAExitScoreDate'],
    ['outcomeAEntryScore', 'outcomeAProgress'],
    ['outcomeAExitScore', 'outcomeAProgress'],
    ['outcomeBEntryScore', 'outcomeBEntryScoreDate'],
    ['outcomeBExitScore', 'outcomeBExitScoreDate'],
    ['outcomeBEntryScore', 'outcomeBProgress'],
    ['outcomeBExitScore', 'outcomeBProgress'],
    ['outcomeCEntryScore', 'outcomeCEntryScoreDate'],
    ['outcomeCExitScore', 'outcomeCExitScoreDate'],
    ['outcomeCEntryScore', 'outcomeCProgress'],
    ['outcomeCExitScore', 'outcomeCProgress'],
  ]
);

/* istanbul ignore next */
export const NPSE_CTEOnlyValidationSchema = yup.object().shape({
  singleParentOrSinglePregnantWomanStatus: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  cteaeDisplacedHomemakerIndicator: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  perkinsPostProgramPlacementIndicator: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
});

/* istanbul ignore next */
export const NPSE_SPEDOnlyValidationSchema = yup.object().shape({
  eligibilityDeterminationDate: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  iepStartDate: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  primaryDisabilityType: yup.string().required(VALIDATION_MESSAGE.REQUIRED),
  significantCognitiveDisabilityIndicator: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  relatedServices: yup.array().required(VALIDATION_MESSAGE.REQUIRED),
  responsibleDistrictAccountabilityId: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  responsibleSchoolAccountabilityId: yup
    .string()
    .required(VALIDATION_MESSAGE.REQUIRED),
});
