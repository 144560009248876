/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataEntryMode } from '../../types/DataEntryMode';
import { NonPublicStudentEntryModel } from '../../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../../types/NonPublicStudentFormViewModel';
import { NonPublicStudentListItemModel } from '../../types/NonPublicStudentListItemModel';
import { NonPublicStudentScheduleFormViewModel } from '../../types/NonPublicStudentScheduleFormViewModel';
import { NonPublicStudentScheduleListItemModel } from '../../types/NonPublicStudentScheduleListItemModel';
import { NonPublicStudentScheduleModel } from '../../types/NonPublicStudentScheduleModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchNonPublicStudentById,
  fetchNonPublicStudentFormViewModel,
  fetchNonPublicStudentListItems,
  fetchNonPublicStudentScheduleByNpsId,
  fetchNonPublicStudentScheduleByScheduleId,
  fetchNonPublicStudentScheduleFormViewModel,
} from '../thunks/nonPublicStudentThunks';

interface NonPublicStudentState {
  schoolYear: string;
  loadingListItems: boolean;
  listItems: NonPublicStudentListItemModel[];

  loadingNonPublicStudent: boolean;
  nonPublicStudent: NonPublicStudentEntryModel;
  scheduleListItems: NonPublicStudentScheduleListItemModel[];
  nonPublicStudentSchedule: NonPublicStudentScheduleModel;

  loadingFormViewModel: boolean;
  formViewModel: NonPublicStudentFormViewModel;
  scheduleFormViewModel: NonPublicStudentScheduleFormViewModel;

  dataEntryMode: DataEntryMode;
}

export const defaultNonPublicStudentState: NonPublicStudentEntryModel = {
  id: '',
  district: '',
  schoolYear: '',
  schoolType: '',
  schoolId: '',
  school: '',
  schoolName: '',
  studentIdentifier: '',
  lastOrSurname: '',
  firstName: '',
  middleName: '',
  birthDate: '',
  personIdentifierSsn: '',
  sex: '',
  hispanicOrLatinoEthnicity: '',
  race: '',
  specialEducation: '',
  englishLearnerStatus: '',
  singleParentOrSinglePregnantWomanStatus: '',
  cteaeDisplacedHomemakerIndicator: '',
  perkinsPostProgramPlacementIndicator: '',
  enrollmentEntryDate: '',
  entryType: '',
  enrollmentStatus: '',
  gradeLevel: '',
  eligibilityDeterminationDate: '',
  iepStartDate: '',
  projectedHSDiplomaType: '',
  programParticipationExitDate: '',
  primaryDisabilityType: '',
  secondaryDisabilityType: '',
  significantCognitiveDisabilityIndicator: '',
  ideaEducationalEnvironment: '',
  relatedServices: [],
  responsibleDistrictAccountabilityId: '',
  responsibleSchoolAccountabilityId: '',
  outcomeAEntryScore: '',
  outcomeAEntryScoreDate: '',
  outcomeAExitScore: '',
  outcomeAExitScoreDate: '',
  outcomeAProgress: '',
  outcomeBEntryScore: '',
  outcomeBEntryScoreDate: '',
  outcomeBExitScore: '',
  outcomeBExitScoreDate: '',
  outcomeBProgress: '',
  outcomeCEntryScore: '',
  outcomeCEntryScoreDate: '',
  outcomeCExitScore: '',
  outcomeCExitScoreDate: '',
  outcomeCProgress: '',
  hasMatch: false,
  ageEvaluationYear: '',
};

export const defaultDataEntryMode = DataEntryMode.NEW;

export const defaultStudentSchedule: NonPublicStudentScheduleModel = {
  id: '',
  npsId: '',
  schoolIdentifier: '',
  schoolName: '',
  staffMemberIdentifier: '',
  staffMemberName: '',
  courseIdentifier: '',
  courseTitle: '',
  courseSectionIdentifier: '',
  courseSectionDescription: '',
  status: '',
};

export const defaultScheduleFormViewModel: NonPublicStudentScheduleFormViewModel =
  {
    defaultSchoolYear: '',
    extendedCourseCodeOptions: [],
    statusOptions: [],
  };

export const defaultFormState: NonPublicStudentFormViewModel = {
  defaultSchoolYear: '',
  // ageEvaluationYear: '',
  districts: [],
  ideaEducationalEnvironments: [],
  schoolTypeOptions: [],
  sexOptions: [],
  hispanicOrLatinoEthnicityOptions: [],
  raceOptions: [],
  specialEducationOptions: [],
  englishLearnerStatusOptions: [],
  enrollmentStatusOptions: [],
  gradeLevelOptions: [],
  singleParentOrSinglePregnantWomanStatusOptions: [],
  cteaeDisplacedHomemakerIndicatorOptions: [],
  perkinsPostProgramPlacementIndicatorOptions: [],
  significantCognitiveDisabilityIndicatorOptions: [],
  primaryDisabilityTypeOptions: [],
  secondaryDisabilityTypeOptions: [],
  relatedServicesOptions: [],
  projectedHSDiplomaTypeOptions: [],
  outcomeExitScoreOptions: [],
  outcomeEntryScoreOptions: [],
  outcomeProgressOptions: [],
};

export const initialState: NonPublicStudentState = {
  schoolYear: '',
  loadingListItems: false,
  listItems: [],
  loadingNonPublicStudent: false,
  nonPublicStudent: defaultNonPublicStudentState,
  nonPublicStudentSchedule: defaultStudentSchedule,
  scheduleListItems: [],
  loadingFormViewModel: false,
  formViewModel: defaultFormState,
  scheduleFormViewModel: defaultScheduleFormViewModel,
  dataEntryMode: defaultDataEntryMode,
};

export const nonPublicStudentSlice = createSlice({
  name: 'nonPublicStudent',
  initialState,
  reducers: {
    setSchoolYearFilter: (state, action: PayloadAction<string>) => {
      state.schoolYear = action.payload;
    },
    clearNonPublicStudent: (state) => {
      state.nonPublicStudent = defaultNonPublicStudentState;
    },
    saveNonPublicStudent: (
      state,
      action: PayloadAction<NonPublicStudentEntryModel>
    ) => {
      state.nonPublicStudent = action.payload;
    },
    clearNonPublicStudentSchedule: (state) => {
      state.nonPublicStudentSchedule = defaultStudentSchedule;
    },
    saveNonPublicStudentSchedule: (
      state,
      action: PayloadAction<NonPublicStudentScheduleModel>
    ) => {
      state.nonPublicStudentSchedule = action.payload;
    },
    setNonPublicStudentDataEntryMode: (
      state,
      action: PayloadAction<DataEntryMode>
    ) => {
      state.dataEntryMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchNonPublicStudentList
    builder.addCase(fetchNonPublicStudentListItems.pending, (state) => {
      state.listItems = [];
      state.loadingListItems = true;
    });
    builder.addCase(
      fetchNonPublicStudentListItems.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.listItems = action.payload;
        }
        state.loadingListItems = false;
      }
    );
    builder.addCase(
      fetchNonPublicStudentListItems.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingListItems = false;
        }
      }
    );

    // fetchNonPublicStudentById
    builder.addCase(fetchNonPublicStudentById.pending, (state) => {
      state.nonPublicStudent = defaultNonPublicStudentState;
      state.loadingNonPublicStudent = true;
    });
    builder.addCase(fetchNonPublicStudentById.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.nonPublicStudent = action.payload;
      }
      state.loadingNonPublicStudent = false;
    });
    builder.addCase(fetchNonPublicStudentById.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loadingNonPublicStudent = false;
      }
    });

    // fetchNonPublicStudentScheduleByNpsId
    builder.addCase(fetchNonPublicStudentScheduleByNpsId.pending, (state) => {
      state.scheduleListItems = [];
      state.loadingNonPublicStudent = true;
    });
    builder.addCase(
      fetchNonPublicStudentScheduleByNpsId.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.scheduleListItems = action.payload;
        }
        state.loadingNonPublicStudent = false;
      }
    );
    builder.addCase(
      fetchNonPublicStudentScheduleByNpsId.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingNonPublicStudent = false;
        }
      }
    );

    // fetchNonPublicStudentScheduleByScheduleId
    builder.addCase(
      fetchNonPublicStudentScheduleByScheduleId.pending,
      (state) => {
        state.nonPublicStudentSchedule = defaultStudentSchedule;
        state.loadingNonPublicStudent = true;
      }
    );
    builder.addCase(
      fetchNonPublicStudentScheduleByScheduleId.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.nonPublicStudentSchedule = action.payload;
        }
        state.loadingNonPublicStudent = false;
      }
    );
    builder.addCase(
      fetchNonPublicStudentScheduleByScheduleId.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingNonPublicStudent = false;
        }
      }
    );

    //fetchNonPublicStudentFormViewModel
    builder.addCase(fetchNonPublicStudentFormViewModel.pending, (state) => {
      state.formViewModel = defaultFormState;
      state.loadingFormViewModel = true;
    });
    builder.addCase(
      fetchNonPublicStudentFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.formViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchNonPublicStudentFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );

    //fetchNonPublicStudentScheduleFormViewModel
    builder.addCase(
      fetchNonPublicStudentScheduleFormViewModel.pending,
      (state) => {
        state.scheduleFormViewModel = defaultScheduleFormViewModel;
        state.loadingFormViewModel = true;
      }
    );
    builder.addCase(
      fetchNonPublicStudentScheduleFormViewModel.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.scheduleFormViewModel = action.payload;
        }
        state.loadingFormViewModel = false;
      }
    );
    builder.addCase(
      fetchNonPublicStudentScheduleFormViewModel.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
          state.loadingFormViewModel = false;
        }
      }
    );
  },
});

export const { setSchoolYearFilter } = nonPublicStudentSlice.actions;

export const {
  clearNonPublicStudent,
  clearNonPublicStudentSchedule,
  saveNonPublicStudent,
  saveNonPublicStudentSchedule,
  setNonPublicStudentDataEntryMode,
} = nonPublicStudentSlice.actions;

export const selectSchoolYear = (state: RootState): string =>
  state.nonPublicStudent.schoolYear;
// listItems
export const selectNonPublicStudentLoadingListItems = (
  state: RootState
): boolean => state.nonPublicStudent.loadingListItems;
export const selectNonPublicStudentListItems = (
  state: RootState
): NonPublicStudentListItemModel[] => state.nonPublicStudent.listItems;

// NonPublicStudent
export const selectNonPublicStudentLoadingNonPublicStudent = (
  state: RootState
): boolean => state.nonPublicStudent.loadingNonPublicStudent;
export const selectNonPublicStudentNonPublicStudent = (
  state: RootState
): NonPublicStudentEntryModel => state.nonPublicStudent.nonPublicStudent;
export const selectNonPublicStudentScheduleListItems = (
  state: RootState
): NonPublicStudentScheduleListItemModel[] =>
  state.nonPublicStudent.scheduleListItems;
export const selectNonPublicStudentScheduleModel = (
  state: RootState
): NonPublicStudentScheduleModel =>
  state.nonPublicStudent.nonPublicStudentSchedule;

// formViewModel
export const selectNonPublicStudentLoadingFormViewModel = (
  state: RootState
): boolean => state.nonPublicStudent.loadingFormViewModel;
export const selectNonPublicStudentFormViewModel = (
  state: RootState
): NonPublicStudentFormViewModel => state.nonPublicStudent.formViewModel;
export const selectNonPublicStudentScheduleFormViewModel = (
  state: RootState
): NonPublicStudentScheduleFormViewModel =>
  state.nonPublicStudent.scheduleFormViewModel;

// dataEntryMode
export const selectNonPublicStudentDataEntryMode = (
  state: RootState
): DataEntryMode => state.nonPublicStudent.dataEntryMode;

export default nonPublicStudentSlice.reducer;
