/* eslint-disable max-len */
import { ApiResponseModel } from '../types/ApiResponseModel';
import { CurrentUserViewModel } from '../types/CurrentUserViewModel';
import { DataRequestConfig } from '../types/DataRequestConfig';
import { UserDetailsViewModel } from '../types/UserDetailsViewModel';
import { UserEditDetailByIdRequestPayload } from '../types/UserEditDetailByIdRequestPayload';
import { UserEditModel } from '../types/UserEditModel';
import { UserEditUpdateRequestPayload } from '../types/UserEditUpdateRequestPayload';
import { UserEditViewModel } from '../types/UserEditViewModel';
import { UserListModel } from '../types/UserListModel';
import { getApiBaseUrl } from './app.service';
import { executeApiRequest, getAuthToken } from './base.service';
import { getHttpClient, HttpClient } from './httpClient';

export const userApiPaths = {
  updateCurrentDistrict: (districtId: string): string =>
    `currentuser/current-district/${districtId}`,
  getCurrentUserInfo: (updateLogin: boolean) =>
    `currentuser?updateLogin=${updateLogin}`,
  getUserList: 'user',
  getUserById: (userId: string): string => `user/${userId}`,
  updateUser: (payload: UserEditUpdateRequestPayload): string =>
    payload.editingDistrictId
      ? `user/edit/${payload.userId}?editingDistrictId=${payload.editingDistrictId}`
      : `user/edit/${payload.userId}`,
  editUserDetails: (payload: UserEditDetailByIdRequestPayload): string =>
    payload.editingDistrictId
      ? `user/edit/${payload.userId}?editingDistrictId=${payload.editingDistrictId}`
      : `user/edit/${payload.userId}`,
  activateUser: (userId: string): string => `user/activate/${userId}`,
  deactivateUser: (userId: string): string => `user/deactivate/${userId}`,
};

export const UserServiceClient = (config?: DataRequestConfig): HttpClient =>
  getHttpClient(getApiBaseUrl(), getAuthToken() || '', config?.signal);

export const getCurrentUser = async (
  config?: DataRequestConfig,
  updateLogin = true
): Promise<ApiResponseModel<CurrentUserViewModel>> => {
  return executeApiRequest<CurrentUserViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.getCurrentUserInfo(updateLogin)
    );
  });
};

export const getUserList = async (
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserListModel>> => {
  return executeApiRequest<UserListModel>(async () => {
    return await UserServiceClient(config).get(userApiPaths.getUserList);
  });
};

export const getUserDetailsViewModel = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserDetailsViewModel>> => {
  return executeApiRequest<UserDetailsViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.getUserById(userId)
    );
  });
};

export const getUserEditViewModel = async (
  payload: UserEditDetailByIdRequestPayload,
  config?: DataRequestConfig
): Promise<ApiResponseModel<UserEditViewModel>> => {
  return executeApiRequest<UserEditViewModel>(async () => {
    return await UserServiceClient(config).get(
      userApiPaths.editUserDetails(payload)
    );
  });
};

export const updateUser = async (
  payload: UserEditUpdateRequestPayload,
  body: UserEditModel,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.updateUser(payload),
      body
    );
  });
};

export const activateUser = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.activateUser(userId)
    );
  });
};

export const deactivateUser = async (
  userId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<unknown>> => {
  return executeApiRequest<unknown>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.deactivateUser(userId)
    );
  });
};

export const updateCurrentDistrict = async (
  districtId: string,
  config?: DataRequestConfig
): Promise<ApiResponseModel<CurrentUserViewModel>> => {
  return executeApiRequest<CurrentUserViewModel>(async () => {
    return await UserServiceClient(config).put(
      userApiPaths.updateCurrentDistrict(districtId)
    );
  });
};
