/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react';
import NPSE_Layout from '../../../../components/FormLayouts/NPSE/NPSE_Layout';
import ManualInputPage from '../../../../components/ManualInputPage/ManualInputPage';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  clearNonPublicStudent,
  saveNonPublicStudent,
  selectNonPublicStudentDataEntryMode,
  selectNonPublicStudentFormViewModel,
  selectNonPublicStudentLoadingFormViewModel,
  selectNonPublicStudentLoadingNonPublicStudent,
  selectNonPublicStudentNonPublicStudent,
  selectSchoolYear,
  setNonPublicStudentDataEntryMode,
} from '../../../../redux/slices/nonPublicStudentSlice';
import {
  fetchNonPublicStudentById,
  fetchNonPublicStudentFormViewModel,
  fetchNonPublicStudentScheduleByNpsId,
} from '../../../../redux/thunks/nonPublicStudentThunks';
import {
  abortPromiseOnUnmount,
  getCurrentUserAccess,
} from '../../../../services/base.service';
import {
  createNonPublicStudent,
  updateNonPublicStudent,
} from '../../../../services/nonPublicStudent.service';
import { appRoutePaths } from '../../../../services/route.service';
import { GLOBAL_DEBUG_MODE } from '../../../../testing/debugFunctions';
import { DataEntryMode } from '../../../../types/DataEntryMode';
import { Tabs_STUDENT } from '../../../../types/NavigationTabs';
import { NonPublicStudentEntryModel } from '../../../../types/NonPublicStudentEntryModel';
import { NonPublicStudentFormViewModel } from '../../../../types/NonPublicStudentFormViewModel';
import { ManualInputCrudAction } from '../../../../types/propTypes/ManualInputPagePropTypes';
import { NonPublicStudentEntryValidationSchema } from '../../../../types/Validation/NonPublicStudentEntryValidationSchema';
import { buildUserAccessNavigationTabItems } from '../../../../utilities/routeUtilities';

const NonPublicStudentEntryInput = (): ReactElement => {
  const dispatch = useAppDispatch();

  const model: NonPublicStudentEntryModel = useAppSelector(
    selectNonPublicStudentNonPublicStudent
  );
  const formViewModel: NonPublicStudentFormViewModel = useAppSelector(
    selectNonPublicStudentFormViewModel
  );
  const isLoadingFormViewModel = useAppSelector(
    selectNonPublicStudentLoadingFormViewModel
  );
  const isLoadingModel = useAppSelector(
    selectNonPublicStudentLoadingNonPublicStudent
  );
  const dataEntryMode: DataEntryMode = useAppSelector(
    selectNonPublicStudentDataEntryMode
  );
  const schoolYear = useAppSelector(selectSchoolYear);
  const isLoading = isLoadingModel || isLoadingFormViewModel;

  const getDistrictDisplay = (): string => {
    if (dataEntryMode === DataEntryMode.EDIT) {
      return model.district;
    } else {
      const user = getCurrentUserAccess();
      return `${user?.currentDistrict?.name} (${user?.currentDistrict?.districtId})`;
    }
  };

  useEffect(() => {
    let promise: unknown;
    if (model.id.length > 0 && dataEntryMode === DataEntryMode.EDIT) {
      promise = dispatch(fetchNonPublicStudentScheduleByNpsId(model.id));
    }

    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, dataEntryMode, model]);

  const getSchoolYear = (): string => {
    if (dataEntryMode == DataEntryMode.EDIT) {
      return model.schoolYear;
    } else {
      // If redux is filled use that, otherwise use the default from the form model
      if (schoolYear !== '') {
        return schoolYear;
      } else {
        return formViewModel.defaultSchoolYear;
      }
    }
  };

  // TODO may need to make this an ACUTAL property that comes
  // from the form model or make sure this process will work going forward.
  /* istanbul ignore next */
  const getYearForAgeEvaluation = (): string => {
    const schoolYearDisplay = getSchoolYear();
    const years = schoolYearDisplay.split('-');
    if (years.length === 2) {
      return years[0].trim();
    }
    return '';
  };

  const getSchoolDisplay = (): string => {
    return dataEntryMode === DataEntryMode.EDIT
      ? `${model.schoolName} (${model.schoolId})`
      : '';
  };

  const intitalValues: NonPublicStudentEntryModel = {
    ...model,
    district: getDistrictDisplay(),
    schoolYear: getSchoolYear(),
    ageEvaluationYear: getYearForAgeEvaluation(),
    hasMatch: dataEntryMode === DataEntryMode.EDIT,
    entryType: 'Initial Enrollment for the School Year',
    school: getSchoolDisplay(),
  };

  return (
    <ManualInputPage<NonPublicStudentEntryModel, NonPublicStudentFormViewModel>
      actions={ManualInputCrudAction.CREATE_AND_EDIT}
      loadingOptions={{
        isLoading: isLoading,
        loadingDataId: 'non-public-student-entry-loader',
      }}
      navigationTabs={buildUserAccessNavigationTabItems(Tabs_STUDENT)}
      classes={{
        pageClass: 'data-input--student--non-public-student-entry--form',
      }}
      parameterOptions={{
        routeParameterName: 'nonPublicStudentId',
        identityName: model.id,
      }}
      routes={{
        backBarRoute: appRoutePaths.NonPublicStudentEntryList,
        cancelRoute: appRoutePaths.NonPublicStudentEntryList,
        createSuccessRoute: appRoutePaths.NonPublicStudentEntryInput,
      }}
      dataFunctions={{
        loadDataDetailById: fetchNonPublicStudentById,
        loadFormDetail: fetchNonPublicStudentFormViewModel,
        setEntryMode: setNonPublicStudentDataEntryMode,
        setData: saveNonPublicStudent,
        clearData: clearNonPublicStudent,
        onCreate: createNonPublicStudent,
        onUpdate: updateNonPublicStudent,
      }}
      formDetails={{
        validationSchema: NonPublicStudentEntryValidationSchema,
        initialFormValues: intitalValues,
        isFormModelEmpty: formViewModel.schoolTypeOptions.length === 0,
      }}
      config={{
        dataEntryMode: dataEntryMode,
        objectVerbiage: 'non public student',
        pageHeader: 'Student',
      }}
      DEV_MODE={GLOBAL_DEBUG_MODE}
    >
      <NPSE_Layout />
    </ManualInputPage>
  );
};

export default NonPublicStudentEntryInput;
