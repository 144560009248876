/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

interface IsRequiredProps {
  updateMailingAddressRequiredFlag: (isRequired: boolean) => void;
}

interface FormValues {
  mailingAddressStreetNumberAndName: string;
  mailingCity: string;
  mailingState: string;
  mailingZipCode: string;
}

const LeaManagement_IsRequired: React.FC<IsRequiredProps> = ({
  updateMailingAddressRequiredFlag,
}) => {
  const { values } = useFormikContext<FormValues>();

  useEffect(() => {
    const isRequired =
      values.mailingAddressStreetNumberAndName === '' &&
      values.mailingCity === '' &&
      values.mailingState === '' &&
      values.mailingZipCode === ''
        ? false
        : true;
    updateMailingAddressRequiredFlag(isRequired);
  }, [values, updateMailingAddressRequiredFlag]);
  return null;
};
export default LeaManagement_IsRequired;
