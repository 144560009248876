/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { Form, Formik } from 'formik';
import { noop } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import ActionButton from '../../components/ActionButton/ActionButton';
import FormCheckboxList from '../../components/FormInputs/FormCheckboxList/FormCheckboxList';
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue';
import Loader from '../../components/Loader/Loader';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import Panel from '../../components/Panel/Panel';
import StyledSingleDropdown from '../../components/StyledSingleDropdown/StyledSingleDropdown';
import { useConfirmationModalContext } from '../../hooks/useConfirmationModalContext';
import {
  mapFormToEditModel,
  mapToFormModel,
} from '../../mappers/UserEditMapper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createNotification } from '../../redux/slices/notificationSlice';
import {
  selectUserDetailLoading,
  selectUserEditViewModel,
  selectUserLoading,
} from '../../redux/slices/userSlice';
import {
  fetchUserEditViewModelById,
  fetchUserEditViewModelByIdAndDistrictId,
} from '../../redux/thunks/userThunks';
import {
  abortPromiseOnUnmount,
  isValidCode,
} from '../../services/base.service';
import { appRoutePaths } from '../../services/route.service';
import { updateUser } from '../../services/user.service';
import { GLOBAL_DEBUG_MODE } from '../../testing/debugFunctions';
import { getMockedOkApiResponse } from '../../testing/mockResponses';
import { ApiErrorModel } from '../../types/ApiErrorModel';
import { ApiResponseModel } from '../../types/ApiResponseModel';
import { PanelType } from '../../types/PanelType';
import { ReactSelectOption } from '../../types/ReactSelectOption';
import { SelectOption } from '../../types/SelectOption';
import { UserEditFormModel } from '../../types/UserEditModel';
import { UserEditUpdateRequestPayload } from '../../types/UserEditUpdateRequestPayload';
import { UserEditViewModel } from '../../types/UserEditViewModel';
import { UserType } from '../../types/UserType';
import { setStyledDropdownSingleValue } from '../../utilities/helperUtilities';
import {
  getDistrictDisplay,
  getRoleDisplayText,
} from '../../utilities/userUtilities';
import DataDomainPermissions from './DataDomainPermissions/DataDomainPermissions';
import './UserEdit.css';

const UserEdit = (): ReactElement => {
  const { userId } = useParams();
  const confirmContext = useConfirmationModalContext();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectUserLoading);
  const isDetailLoading = useAppSelector(selectUserDetailLoading);
  const details: UserEditViewModel = useAppSelector(selectUserEditViewModel);
  const [error, setError] = useState<ApiErrorModel | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const userIsStateAdmin =
    details.user.userType === UserType.State && details.user.isAdmin;
  /* istanbul ignore next */
  const getUserId = (): string => userId || '';
  const districtSet =
    details.user.userType === UserType.District
      ? details.user.assignedDistricts
      : details.user.currentDistrict
      ? [details.user.currentDistrict]
      : [];

  useEffect(() => {
    let promise: unknown = undefined;
    if (userId) {
      promise = dispatch(fetchUserEditViewModelById(userId));
    }
    return () => {
      abortPromiseOnUnmount(promise);
    };
  }, [dispatch, userId]);

  const saveUpdates = async (values: UserEditFormModel): Promise<void> => {
    setIsUpdating(true);

    if (userId) {
      const savingModel = mapFormToEditModel(
        values,
        details.assignableDataDomainPermissions.map((domain) => domain.name),
        details.assignableSchools
      );

      const payload: UserEditUpdateRequestPayload = {
        userId: getUserId(),
        ...(details.user.isMultiDistrictUser && {
          editingDistrictId: details.user.editingDistrictId,
        }),
      };

      const result = await updateUser(payload, savingModel);
      if (isValidCode(result.status)) {
        setError(undefined);
        dispatch(
          createNotification({
            severity: 'success',
            children: 'User details updated',
          })
        );
      } else {
        setError(result.error);
      }
    }

    setIsUpdating(false);
  };

  const handleReset = (resetFunc: Function): void => {
    setError(undefined);
    resetFunc(mapToFormModel(details));
  };

  const statewideDataDomainOptions = details.assignableStatewideDataDomains.map(
    (dataDomain): SelectOption => ({
      text: getRoleDisplayText(dataDomain),
      value: dataDomain,
      isDisabled: userIsStateAdmin,
    })
  );

  const schoolOptions: SelectOption[] = details.assignableSchools.map(
    (school) => ({
      text: `${school.name} (${school.schoolId})`,
      value: school.schoolId,
    })
  );

  const disableSubmit = (): boolean => {
    return isLoading || isUpdating;
  };

  const handleDirtyMultiDistrict = async (newValue: string): Promise<void> => {
    confirmContext.setOptions({
      title: `Confirm Data Loss?`,
      content: (
        <div className="confirm--message">
          Are you sure you want move to another district? This action will lose
          any changes you already made for this district. Press &quot;No&quot;
          to stay on this district and manually save the form or press
          &quot;Yes&quot; to change district without saving.
        </div>
      ),
      confirmText: 'Yes',
      cancelText: 'No',
      onOk: async (): Promise<ApiResponseModel<unknown>> => {
        dispatch(
          fetchUserEditViewModelByIdAndDistrictId({
            userId: getUserId(),
            editingDistrictId: newValue,
          })
        );
        // Since we dont really want to hold the user on the modal and want
        // the normal error popup to occur if the filtering fails, we
        // are just mocking the response to be successful
        return getMockedOkApiResponse({});
      },
    });

    await confirmContext.showConfirmation();
  };

  const handleEditingDistrictChange = async (
    selected: SingleValue<ReactSelectOption>,
    isFormDirty: boolean
  ): Promise<void> => {
    /* istanbul ignore next */
    const value = selected?.value || '';

    // No change was made
    if (value === details.user.editingDistrictId) {
      return Promise.resolve();
    }

    if (!isFormDirty) {
      dispatch(
        fetchUserEditViewModelByIdAndDistrictId({
          userId: getUserId(),
          editingDistrictId: value,
        })
      );
    } else {
      await handleDirtyMultiDistrict(value);
    }

    return Promise.resolve();
  };

  return (
    <NavigationPage
      heading={'Edit User'}
      pageClass="edit-user"
      isLoading={isLoading}
      loadingDataId="edit-user-loader"
      loadingText="Loading user"
      contentClass="user-detail-layout"
      APIError={error}
      backBarLocation={appRoutePaths.UserDetail(userId)}
    >
      <Panel panelType={PanelType.INFO} heading="General Information">
        <LabelAndValue
          cypressDataId="user-name"
          testId="user-name-value"
          displayName="Name"
          value={details.user.name}
        />
        <LabelAndValue
          cypressDataId="user-email"
          testId="user-email-value"
          displayName="Email"
          value={details.user.email}
        />
        <LabelAndValue
          cypressDataId="user-district-access"
          testId="user-district-value"
          displayName="District(s)"
          value={getDistrictDisplay(districtSet)}
        />
      </Panel>

      {!isDetailLoading ? (
        <>
          {details.user.currentDistrict?.districtId !== undefined ? (
            <Formik
              validateOnChange={true}
              validateOnMount={true}
              initialValues={mapToFormModel(details)}
              onSubmit={async (values, helpers) => {
                await saveUpdates(values);
                helpers.resetForm({ values });
              }}
            >
              {({ values, resetForm, dirty }) => (
                <>
                  <Form>
                    {details.assignableStatewideDataDomains.length > 0 && (
                      <div className="assignment-section">
                        <Panel
                          panelType={PanelType.INFO}
                          heading="Statewide Data Domains"
                        >
                          <div data-testid="permission-checklist">
                            <FormCheckboxList
                              addAllCheckbox={
                                statewideDataDomainOptions.length > 1 &&
                                !userIsStateAdmin
                              }
                              field={'statewideDataDomains'}
                              options={statewideDataDomainOptions}
                            />
                          </div>
                        </Panel>
                      </div>
                    )}

                    {details.user.isMultiDistrictUser && (
                      <div className="multi-district-dropdown">
                        <StyledSingleDropdown
                          showLabel={true}
                          labelText="Related District for Data Domain Permissions"
                          fieldName="editingDistrictId"
                          options={details.multiDistrictOptions}
                          onChange={(e) =>
                            handleEditingDistrictChange(e, dirty)
                          }
                          value={setStyledDropdownSingleValue(
                            details.multiDistrictOptions,
                            details.user.editingDistrictId
                          )}
                        />
                      </div>
                    )}
                    <DataDomainPermissions
                      model={details}
                      schoolOptions={schoolOptions}
                      clearParentError={
                        /* istanbul ignore next */
                        () => setError(undefined)
                      }
                    />

                    <div className="action-row">
                      <ActionButton
                        onClick={() => handleReset(resetForm)}
                        dataTestId="edit-reset"
                        cypressDataId="edit-reset"
                        tooltipText="Reset Form"
                      >
                        <span>Reset</span>
                      </ActionButton>
                      <ActionButton
                        buttonType="submit"
                        classes="button--secondary update-user-button"
                        onClick={() => noop()}
                        dataTestId="edit-submit"
                        disabled={disableSubmit()}
                        cypressDataId="edit-submit"
                        tooltipText="Update User"
                        loading={isUpdating}
                      >
                        <span>Update User</span>
                      </ActionButton>
                    </div>
                  </Form>
                  {
                    /* istanbul ignore next */
                    GLOBAL_DEBUG_MODE && (
                      <pre>FORM Values: {JSON.stringify(values, null, 2)}</pre>
                    )
                  }
                </>
              )}
            </Formik>
          ) : (
            <div className="empty--list-text">
              {/* Needed if user tries to access page by URL */}
              <p>No district assigned. Register user for a district.</p>
            </div>
          )}
        </>
      ) : (
        <div className="detail-loading-container">
          <Loader
            dataTestId="detail-loader"
            message="Loading district domain permission for district"
          />
        </div>
      )}
    </NavigationPage>
  );
};

export default UserEdit;
